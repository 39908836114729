import styles from './Alert.module.css'
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {deleteAlert, getAlertDetail, getAlerts, getAlertUnseenDocuments, resetAlert} from "../../services/api";
import {FaRegTrashCan} from "react-icons/fa6";
import DocumentCard from "../DocumentCard/DocumentCard";
import {useGlobalState, setLoading, useGlobalDispatch} from "../../context/GlobalState";
import {AiOutlineClose} from "react-icons/ai";
import AggregationChart from "../SearchViewer/AggregationChart";
import ReactJson from "react-json-view";
import {LuTimerReset} from "react-icons/lu";
import {SubTitle, Title} from "../Headings/Heading";

const Alert = () => {
    const dispatch = useGlobalDispatch();
    const [alerts, setAlerts] = useState([])
    const { user, isAuthenticated, logout } = useAuth();
    const [docList, setDocList] = useState(null);
    const [docTotal, setDocTotal] = useState(0);
    const [detail, setDetail] = useState(null);
    const [result, setResult] = useState({});

    useEffect(()=> {
        fetchData();
    }, [])

    const fetchData = async () => {
        const result = await getAlerts(user.token)
        setAlerts( result)
    }

    const handleDeleteAlert = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Delete alert?")) return;
        await deleteAlert(user.token, id);
        fetchData();
    }

    const handleResetAlert = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Reset counter?")) return;
        await resetAlert(user.token, id);
        fetchData();
    }

    const loadUnseen = async (id) => {
        try {
            dispatch(setLoading(true));
            const results = await getAlertUnseenDocuments(user.token, id);
            setDocList(results?.hits?.hits || []);
            setDocTotal(results?.hits?.total?.value);
            setDetail(results?.query);
            setResult(results);

        } catch( error) {
            console.log( error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSelectAlert = async (id) => {
        try {
            dispatch(setLoading(true));
            const result = await getAlertDetail(user.token, id);
            setDetail(result);
            setDocList(null);

        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setLoading(false));
        }

    }


    const documentsDiv = !!docList && <div className={styles["alert-docs-container"]}>
        <div className={styles["result-bar"]}>
            <Title>{`Encontrados ${docTotal} documents`}</Title>
            <SubTitle>Exibidos {result?.query?.from} a {result?.query?.from + docList.length} artigos</SubTitle>

        </div>
        <div className={styles["document-list"]}>
            {docList.map((doc, index) => (
                <DocumentCard
                    key={index}
                    result = {doc}
                    onClick = {setDetail}
                    filters = {null}
                    />
                ))}
        </div>
    </div>

    const detailContent = detail && (
        <div className={styles["alert-detail-content"]}>
            <ReactJson src={detail} theme={"twilight"}/>
        </div>
    );



    return (
        <div className={styles["container"]}>
            <div className={styles["alert-container"]}>
                <h2>Meus alertas</h2>
                <div className={styles["alert-list-container"]}>
                    {alerts.map((_alert, index) => (<div className={styles["row"]}>
                        <div className={styles["alert-title"]}
                             onClick={() => handleSelectAlert(_alert.id)}
                        >{_alert.name}</div>
                        <div className={styles["action-button"]}
                             onClick={() => handleDeleteAlert(_alert.id)}
                             title={"delete alert"}
                        ><FaRegTrashCan/></div>
                        {!!_alert["unseen_document_count"] && _alert["unseen_document_count"] > 0 && <>
                            <div className={styles["count"]}
                                 onClick={() => loadUnseen(_alert.id)}
                                 title={"view documents"}
                            >{_alert["unseen_document_count"]}</div>
                            <div className={styles["action-button"]}
                                 onClick={() => handleResetAlert(_alert.id)}
                                 title={"reset counter"}
                            ><LuTimerReset/></div>
                        </>}
                    </div>))}
                </div>
            </div>
            {/*<div className={styles["alert-docs-container"]}>*/}
            {documentsDiv}
            {/*</div>*/}
            {detailContent}
        </div>

    )
}

export default Alert;
