import React, {Suspense, useEffect} from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styles from './App.module.css';
import LoginPage from './components/LoginPage/LoginPage';
import {useGlobalDispatch, logoutUser, setLoading, useGlobalState} from './context/GlobalState';
import { useAuth } from './hooks/useAuth';
import Search from "./components/Search/Search";
import Chat from "./components/Chat/Chat";
import NavBar from "./components/NavBar/NavBar";
import Alert from "./components/Alert/Alert";
import SavedSearch from "./components/SavedSearch/SavedSearch";
import PlainQuery from "./components/PlainQuery/PlainQuery";
import Monitor from "./components/Monitor/Monitor";
import SearchPanel from "./components/SearchPanel/SearchPanel";
import InfoMessage from "./components/InfoMessage/InfoMessage";

function App() {
  const { user, isAuthenticated, } = useAuth();
  const dispatch = useGlobalDispatch();
  const {loading} = useGlobalState();

  useEffect(() => {
    if (user && user.token) {
      const tokenExpiration = JSON.parse(atob(user.token.split('.')[1])).exp * 1000;
      if (Date.now() >= tokenExpiration) {
        localStorage.removeItem('user');
        dispatch(logoutUser());
      }
    }
  }, [user, dispatch]);

  if (!isAuthenticated()) {
    return <LoginPage />;
  }

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <InfoMessage/>
        <div className={styles["app"]}>
          <div className={styles["nav-bar"]}>
            <NavBar/>
          </div>
          <Routes>
            <Route path="/search" element={<SearchPanel />} />
            <Route path="/saved-search" element={<SavedSearch/>} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/plain-query" element={<PlainQuery />} />
            <Route path="/monitor" element={<Monitor />} />
            <Route path="/" element={<Navigate to="/search" />} /> {/* Default route */}
          </Routes>
          {loading && <div className={"loading"}>loading..</div>}
          {/*<div className={"loading"}>loading..</div>*/}
        </div>
        </Router>
      </Suspense>
);
}

export default App;
