import React, { useState } from 'react';
// import { login } from '../../services/api';
import { useAuth } from '../../hooks/useAuth'
import styles from "./LoginPage.module.css"

const LoginPage = ({ onLogin }) => {
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async () => {
        setError('');
        try {
            await login(username, password)
        } catch (err) {
            console.log('Login error:', err);
            setError('Invalid credentials');
        }
    };

    return (
        <div className={styles["login-page"]}>
            <img
                src="/android-chrome-512x512.png"
                alt="App Logo"
                className={styles["app-logo"]}
            />
            <h2>Query for Radar</h2>
            <div className={styles["panel"]}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Login</button>
                {error && <p>{error}</p>}
            </div>
        </div>
    );
};

export default LoginPage;
