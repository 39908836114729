import React, {useState, useEffect, useContext} from 'react';
import styles from './InfoMessage.module.css';
import {setInfoMessage, useGlobalDispatch, useGlobalState} from "../../context/GlobalState";


function InfoMessage() {
    const dispatch = useGlobalDispatch();
    const {infoMessage} = useGlobalState();

    useEffect(() => {
        if (infoMessage) {
            console.log( infoMessage);
            const timer = setTimeout(() => {
                dispatch( setInfoMessage( null));
            }, 3000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [infoMessage]);

    return infoMessage ? (
        <div className={styles["info-message"]}>
            {typeof(infoMessage) == "string"?infoMessage:JSON.stringify(infoMessage)}
        </div>
    ) : null;
}

export default InfoMessage;
