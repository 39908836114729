import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8081';

const getHeaders = (token) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});

// Login function using axios
export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/v2/token`, {
      email: username,
      password: password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    localStorage.setItem('jwt_token', data.access_token);
    return data.access_token;
  } catch (error) {
    console.error('Login failed:', error);
    throw new Error('Login failed');
  }
};


export const createAndActivateRadar = async (token, radarName,
                                             query,
                                             keywords,
                                             combine_mode,
                                             isExactKeyword,
                                             url,
                                             title,
                                             filters = {}, sortBy,
                                             aggregateBy,
                                             from = 0, size = 5) => {
  const response = await axios.post(`${API_BASE_URL}/api/v2/alerts`,
      {
        radar_name: radarName,
        semantic_query: query,
        keyword_exact_query: isExactKeyword? keywords: '',
        keyword_fuzzy_query: isExactKeyword? '': keywords,
        url: url,
        title: title,
        combine_mode,
        keyword_exact_match: isExactKeyword,
        filters: JSON.stringify(filters)
      },
      {
        headers: getHeaders(token)
      }
  );

  return response.data;
};




// Search documents function using axios
export const searchDocuments = async (token,
                                      query,
                                      keywords,
                                      combine_mode,
                                      isExactKeyword,
                                      url,
                                      title,
                                      filters = {}, sortBy,
                                      aggregateBy,
                                      from = 0, size = 5) => {
  console.log( 'filters: ', filters);


  const response = await axios.get(`${API_BASE_URL}/api/v2/documents/search`, {
    headers: getHeaders(token),
    params: {
      semantic_query: query,
      keyword_exact_query: isExactKeyword? keywords: '',
      keyword_fuzzy_query: isExactKeyword? '': keywords,
      url: url,
      title: title,
      combine_mode,
      filters: JSON.stringify(filters),
      sort_by: sortBy,
      aggregate_by: aggregateBy,
      from: from,
      size: size,
    },
  });

  return response.data;
};

export const saveSearch = async (token, query,
                                 keywords,
                                 combine_mode,
                                 isExactKeyword,
                                 url,
                                 title,
                                 filters = {}, sortBy,
                                      aggregateBy) => {
  console.log( 'filters: ', filters);


  const response = await axios.get(`${API_BASE_URL}/api/v2/documents/search/save`, {
    headers: getHeaders(token),
    params: {
      name: null,
      semantic_query: query,
      keyword_exact_query: isExactKeyword? keywords: '',
      keyword_fuzzy_query: isExactKeyword? '': keywords,
      url: url,
      title: title,
      combine_mode,
      filters: JSON.stringify(filters),
      sort_by: sortBy,
      aggregate_by: aggregateBy
    },
  });

  return response.data;
};




export const getIndexes = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/indexes`, {
    headers: getHeaders(token)
  });

  return response.data;
};


export const getSavedSearchList = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/saved_search`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const deleteSavedSearch = async (token, id) => {
  await axios.delete(`${API_BASE_URL}/api/v2/saved_search/${id}`, {
    headers: getHeaders(token)
  });
};

export const getAlerts = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const deleteAlert = async (token, id) => {
  await axios.delete(`${API_BASE_URL}/api/v2/alerts/${id}`, {
    headers: getHeaders(token)
  });
};

export const resetAlert = async (token, id) => {
  await axios.put(`${API_BASE_URL}/api/v2/alerts/${id}/reset`, null, {
    headers: getHeaders(token)
  });
};

export const getAlertUnseenDocuments = async (token, id) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts/${id}/unseen`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getAlertDetail = async (token, id) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/alerts/${id}`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const runPlainQuery = async (token, query) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/query/plain`, {
    headers: getHeaders(token),
    params: {
      query
    },
  });
  return response.data;
};

export const toggleSaveSearchAlert = async (token, id) => {
  await axios.put(`${API_BASE_URL}/api/v2/saved_search/${id}/toggle-alert`, null, {
    headers: getHeaders(token)
  });
}

export const getLogCounts = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-count`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getQueueCount = async (token) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/queue-count`, {
    headers: getHeaders(token)
  });

  return response.data;
};

export const getErrorLog = async (token, date, service) => {
  const response = await axios.get(`${API_BASE_URL}/api/v2/stats/log-list`, {
    headers: getHeaders(token),
    params: {
      category: "error",
      date,
      service
    },
  });

  return response.data;
};
