import styles from './Monitor.module.css'
import React, {useEffect, useRef, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {getAlerts, getErrorLog, getLogCounts, getQueueCount} from "../../services/api"
import {setLoading, useGlobalDispatch} from "../../context/GlobalState";
import {FaRegTrashCan} from "react-icons/fa6";
import {LuTimerReset} from "react-icons/lu";
import ReactJson from "react-json-view";

const keys = ["url-frontier-g1", "web-scrap-scheduler", "web-scrap-receiver", "broadcast-receiver", "content-processor", "indexer", "alert-monitor"]

const Monitor = () => {
    const dispatch = useGlobalDispatch();
    const [logCount, setLogCount] = useState(null);
    const [queueCount, setQueueCount] = useState([]);
    const { user, isAuthenticated, logout } = useAuth();
    const [ errorList, setErrorList] = useState([]);
    const [fetchError, setFetchError] = useState(null); // State to track fetch errors
    const hasFetchedData = useRef(false); // Ref to track if data has been fetched

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchData();
            hasFetchedData.current = true; // Mark as fetched
        }
    }, []);

    const fetchData = async () => {
        try {
            const result = await getLogCounts(user.token)
            setLogCount( result);

            const queueCountResult = await getQueueCount(user.token)
            setQueueCount( queueCountResult);
        } catch (error) {
            console.error("Error fetching data:", error);
            setFetchError("Failed to fetch data. Please try again later.");
        }
    }

    async function loadErrors(date, k) {
        try {
            dispatch(setLoading(true));
            const result = await getErrorLog(user.token, date, k);
            setErrorList(result);
        } catch (error) {
            console.error("Error loading errors:", error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const errorListDiv = (errorList && errorList.length> 0 && <div className={styles["error-list-container"]}>
        {errorList.map((_item, index) => (<div>
            <ReactJson src={_item} theme={"twilight"}/>
        </div>))}
    </div>)

    const zeroAsNull = (d) => {
        return d===0?'-':d
    }

    return (
        <div className={styles["container"]}>
            <div className={styles["data-container"]}>
                {fetchError && <div className={styles["error-message"]}>{fetchError}</div>}
                <h2>Queue Counts</h2>
                <div className={styles["list-container"]}>
                    {queueCount.map((_item, index) => (<div className={styles["queue-row"]}>
                        <div className={styles["queue-name"]}>
                            {_item.name}
                        </div>
                        <div className={styles["queue-count"]}>
                            {_item.count}
                        </div>
                    </div>))}
                </div>


                <h2>Log Counts</h2>
                {logCount && (
                <div className={styles["list-container"]}>
                    <div className={styles["item-row"]}>
                        <div className={styles["item-title"]}>Service</div>
                        {logCount.dates.map((k) => (<div className={styles["item-block"]}>
                            {k.split('-').pop()}
                        </div>))}
                    </div>
                    {logCount.count.map((_item, index) => (<div className={styles["item-row"]}>
                        <div className={styles["item-title"]}>
                            {_item.service}
                        </div>
                        {logCount.dates.map((k) => (<div className={styles["item-block"]}>
                            <div>
                                {(_item.value[k] || {})["activity"]}
                            </div>
                            <div className={styles["error"]}
                                 onClick={() => loadErrors(k, _item.service)}
                            >
                                {zeroAsNull((_item.value[k] || {})["error"])}
                            </div>
                        </div>))}
                    </div>))}
                </div>)}
            </div>
            {errorListDiv}
        </div>
    )
}

export default Monitor;
