import React, { useMemo } from 'react';
import { ResponsiveContainer, LineChart, BarChart, PieChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Bar, Pie, Cell } from 'recharts';
// import styles from './YourStyles.module.css';
import styles from './SearchViewer.module.css';
import {SimpleSubTitle, SubSectionTitle} from "../Headings/Heading";

// Define colors for pie chart
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

const AggregationChart = ({ new_aggregate }) => {
    // Helper function to render a line chart for date-based aggregations
    const renderLineChart = (aggregate) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            date: bucket.key_as_string, // The date from the aggregation
            count: bucket.doc_count      // The document count for that date
        }));

        return (
            <div className={styles["histo-container"]}>
                <SimpleSubTitle>Document Distribution Over Time</SimpleSubTitle>
                <ResponsiveContainer width="100%" height={250}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                        <XAxis dataKey="date" stroke="#ddd" />
                        <YAxis stroke="#ddd" />
                        <Tooltip />
                        <Line type="monotone" dataKey="count" stroke="#82ca9d" strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    };

    // Helper function to render a bar chart for categorical aggregations
    const renderBarChart = (aggregate, title) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            name: bucket.key, // The category name
            count: bucket.doc_count // The document count for that category
        }));

        return (
            <div className={styles["histo-container"]}>
                <h2>{title}</h2>
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                        <XAxis dataKey="name" stroke="#ddd" />
                        <YAxis stroke="#ddd" />
                        <Tooltip />
                        <Bar dataKey="count" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    // Helper function to render a pie chart for categorical aggregations
    const renderPieChart = (aggregate, title) => {
        const chartData = aggregate.buckets?.map(bucket => ({
            name: bucket.key, // The category name
            count: bucket.doc_count // The document count for that category
        }));

        return (
            <div className={styles["histo-container"]}>
                <h2>{title}</h2>
                <ResponsiveContainer width="100%" height={250}>
                    <PieChart>
                        <Pie
                            data={chartData}
                            dataKey="count"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    };

    // Memoized rendering of charts
    const divHisto = useMemo(() => {
        if (!new_aggregate) return null;

        return (
            <div>
                {/* Render the date-based aggregation (document_date_agg) as a line chart */}
                {new_aggregate.document_date_agg && renderLineChart(new_aggregate.document_date_agg)}

                {/* Render categorical aggregations as bar or pie charts */}
                {new_aggregate.source_agg && renderBarChart(new_aggregate.source_agg, "Source Distribution")}
                {new_aggregate.location_agg && renderPieChart(new_aggregate.location_agg, "Location Distribution")}
                {new_aggregate.subjects_agg && renderPieChart(new_aggregate.subjects_agg, "Subjects Distribution")}
                {new_aggregate.entities_agg && renderPieChart(new_aggregate.entities_agg, "Entities Distribution")}
                {new_aggregate.authors_agg && renderPieChart(new_aggregate.authors_agg, "Authors Distribution")}
                {new_aggregate.main_topics_agg && renderPieChart(new_aggregate.main_topics_agg, "Main Topics Distribution")}
                {new_aggregate.sentiment_agg && renderBarChart(new_aggregate.sentiment_agg, "Sentiment Distribution")}
            </div>
        );
    }, [new_aggregate]); // Only recalculate when showHisto or new_aggregate changes

    return divHisto;
};

export default AggregationChart;
