import React, {useEffect, useState} from 'react';
import {
    FaArrowRight,
    FaCheck,
    FaCheckCircle,
    FaCircle,
    FaHistory,
    FaSearch,
    FaToggleOff,
    FaToggleOn
} from 'react-icons/fa';
import styles from './TopBar.module.css';
import {createAndActivateRadar, getIndexes, getSavedSearchList, searchDocuments} from "../../services/api";
import {setSearchResults, useGlobalDispatch, useGlobalState, setLoading} from "../../context/GlobalState";
import {useAuth} from "../../hooks/useAuth";
import ContextModal from "../ContextModal/ContextModal";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // Import the styles for the date picker

const sortOptions = [
    {code: "relevance", name: "relevância (score)"},
    {code: "date-desc", name: "data da publicação - desc"},
    {code: "date-asc", name: "data da publicação - asc"},
    {code: "index", name: "data indexada - desc"},
]
const aggOptions = [
    {code: "document_date", name: "data do documento"},
    {code: "source", name: "fonte"},
    {code: "location", name: "localidade"},
    {code: "subjects", name: "assunto"},
    {code: "entities", name: "ente"},
    {code: "authors", name: "autor"},
    {code: "main_topics", name: "tema principal"},
    {code: "sentiment", name: "sentimento"},
]

let cachedIndexes = null;
let onetimeSearchTimestamp = null;

const INDEX_NAME = "documents4_1"

const SearchPanel = () => {
    const dispatch = useGlobalDispatch();
    // const { searchResults } = useGlobalState();
    const { user, isAuthenticated, logout } = useAuth();
    const [ advancedSearch, setAdvancedSearch ] = useState(false);
    const [query, setQuery] = useState('');
    const [keywords, setKeywords] = useState('');
    const [isUnion, setIsUnion] = useState(true); // Default is 'UNION'
    const [sortBy, setSortBy] = useState("date-desc");
    const [aggregateBy, setAggregateBy] = useState("document_date");
    const {loading} = useGlobalDispatch();
    // const [loading, setLoading] = useState(false);
    const [savedSearchList, setSavedSearchList] = useState([]);
    const [filters, setFilters] = useState({
        source: '',
        fromDate: '',
        untilDate: '',
        sentiment: {
            neutral: false,
            positive: false,
            negative: false,
        },
        main_topics: '',
        subjects: '',
        entities: '',
        authors: '',
        location: '',
    });

    const [showSaved, setShowSaved] = useState(null);

    const [indexes, setIndexes] = useState(cachedIndexes);
    useEffect(() => {
        async function fetchData() {
            if (!cachedIndexes) {
                const newIndexes = await getIndexes(user.token);
                cachedIndexes = newIndexes;
                console.log('cachedIndexes', cachedIndexes);
                setIndexes(newIndexes);
            }
        }
        fetchData();
    }, []);


    useEffect(()=> {
        async function search() {
            if (!onetimeSearchTimestamp) {
                await onSearch();
                onetimeSearchTimestamp = new Date();
            }
        }
        search();
    }, [])

    useEffect(()=> {
        loadSavedSearch();
    }, [])

    const loadSavedSearch = async () => {
        const searchList = await getSavedSearchList(user.token);
        setSavedSearchList(searchList)
    };



    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const onSearch = async (params = {}, from=0, size=10) => {
        const _filters = {...filters}
        _filters['sentiment'] = Object.entries(_filters['sentiment'])
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            // const results = await searchDocuments({query, ...advancedParams}, user.token);
            const results = await searchDocuments(user.token, query, keywords,
                isUnion? "union": "and", _filters,
                sortBy, aggregateBy, from, size);
            dispatch(setSearchResults({
                filters: _filters,
                query,
                keywords,
                isUnion,
                sortBy,
                aggregateBy,
                from,
                size,
                results
            }));
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    };

    const criarEAtivarRadar = async () => {
        const _filters = {...filters}
        _filters['sentiment'] = Object.entries(_filters['sentiment'])
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            // const results = await searchDocuments({query, ...advancedParams}, user.token);
            await createAndActivateRadar(user.token, radarName, query, keywords,
                isUnion? "union": "and", _filters);
            setRadarName('');
            alert( 'Criado! Confira na aba de radares.')
        } catch (error) {
            alert( error.message);
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    }


    const handleInputKeyDown = async (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Prevent the default action to avoid any unwanted behavior
            event.preventDefault();
            await onSearch();
        }
    }


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((filters) => ({ ...filters, [name]: value }));
    };

    const handleSentimentChange = (e) => {
        const { name, checked } = e.target;
        setFilters({
            ...filters,
            sentiment: {
                ...filters.sentiment,
                [name]: checked
            }
        });
    };


    const filterOptions = (
        <div className={styles['filters-container']}>
            {/* fonte */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Fonte da notícia</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>ae-broadcast g1.globo</span>
                </div>
                <input
                    type="text"
                    name="source"
                    onKeyDown={handleInputKeyDown}
                    value={filters.source}
                    onChange={handleFilterChange}
                />
            </div>
            {/* data do documento (de - até)*/}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Data de publicação da notícia</div>
                <div className={styles["filter-description"]}>Selecione a data de publicação (de - até)</div>
                {/*<div className={styles['date-picker-group']}>*/}
                <div>
                    <label>De: </label>
                    <input
                        type="date"
                        name="fromDate"
                        value={filters.fromDate}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                    />
                </div>
                <div>
                    <label>Até: </label>
                    <input
                        type="date"
                        name="untilDate"
                        value={filters.untilDate}
                        onChange={handleFilterChange}
                        placeholder="YYYY-MM-DD"
                        min={filters.fromDate} // Ensure 'until' date is not before 'from' date
                    />
                </div>
            </div>
            {/*</div>*/}

            {/* sentimento */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Sentimento</div>
                <div className={styles["filter-description"]}>Selecione um ou mais sentimentos</div>
                <div className={styles['checkbox-group']}>
                    <label>
                        <input
                            type="checkbox"
                            name="neutral"
                            checked={filters.sentiment.neutral}
                            onChange={handleSentimentChange}
                        />
                        Neutro
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="positive"
                            checked={filters.sentiment.positive}
                            onChange={handleSentimentChange}
                        />
                        Positivo
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="negative"
                            checked={filters.sentiment.negative}
                            onChange={handleSentimentChange}
                        />
                        Negativo
                    </label>
                </div>
            </div>

            {/* temas principais */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Temas principais</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Mercado de cacau"</span>
                </div>
                <input
                    type="text"
                    name="main_topics"
                    onKeyDown={handleInputKeyDown}
                    value={filters.main_topics}
                    onChange={handleFilterChange}
                />
            </div>

            {/* assuntos */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Assuntos</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>Investimentos "Acões de empresas"</span>
                </div>
                <input
                    type="text"
                    name="subjects"
                    onKeyDown={handleInputKeyDown}
                    value={filters.subjects}
                    onChange={handleFilterChange}
                />
            </div>
            {/* entities */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Entes (pessoa ou empresa)</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>Technobrás "ACME Corp" "José Silva"</span>
                </div>
                <input
                    type="text"
                    name="entities"
                    onKeyDown={handleInputKeyDown}
                    value={filters.entities}
                    onChange={handleFilterChange}
                />
            </div>
            {/* autores */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Autores</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Jorge Amado"</span>
                </div>
                <input
                    type="text"
                    name="authors"
                    onKeyDown={handleInputKeyDown}
                    value={filters.authors}
                    onChange={handleFilterChange}
                />
            </div>
            {/* localidade */}
            <div className={styles['input-group']}>
                <div className={styles["filter-title"]}>Localidade</div>
                <div className={styles["filter-description"]}>Ex: <span className={styles["instructions-mono"]}>"Mato grosso"</span>
                </div>
                <input
                    type="text"
                    name="location"
                    onKeyDown={handleInputKeyDown}
                    value={filters.location}
                    onChange={handleFilterChange}
                />
            </div>
        </div>
    )

    const [radarName, setRadarName] = useState(null);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState('');


    function selectSavedSearch(id) {
        const saved = savedSearchList.find(item => item.id === id);
        console.log( 'selected: ', saved)
        setKeywords(saved?.keywords);
        setQuery(saved?.q);
        setSortBy(saved?.sort_by);
        setAggregateBy(saved?.aggregate_by);
        setIsUnion(saved?.combine_mode === "union");
        setFilters({
            source: saved.filters?.source,
            fromDate: saved.filters?.fromDate,
            untilDate: saved.filters?.untilDate,
            sentiment: {
                neutral: (saved.filters?.sentiment || []).includes('neutral'),
                positive: (saved.filters?.sentiment || []).includes('positive'),
                negative: (saved.filters?.sentiment || []).includes('negative'),
            },
            main_topics: saved.filters?.main_topics,
            subjects: saved.filters?.subjects,
            entities: saved.filters?.entities,
            authors: saved.filters?.authors,
            location: saved.filters?.location,
        });

        setSelectedSavedSearch(id);
    }

    const divSavedSearchModal = !!showSaved && (
        <ContextModal
            clickPosition ={showSaved}
            handleClose={() => setShowSaved(null)} closeLabel={"Close"}
            // nonblocking = {true}
        >
            <div className={styles['modal-list']}>
                {savedSearchList.map((item) => (
                    <div key={item.id}
                         className={styles["modal-list-items"]}
                         onClick = {()=> {
                             selectSavedSearch(item.id);
                             setShowSaved(null);
                         }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </ContextModal>
    )


    return ( <>
            <div className={styles["top-bar"]}>
                {/*<div className={styles['info']}>*/}
                {/*    {indexes && <>*/}
                {/*        <div className={styles['info-row']}>*/}
                {/*            <div className={styles['info-label']}>Index name:</div>*/}
                {/*            <div className={styles['info-value']}>{indexes[INDEX_NAME]["index"]}</div>*/}
                {/*        </div>*/}
                {/*        <div className={styles['info-row']}>*/}
                {/*            <div className={styles['info-label']}>Number of documents:</div>*/}
                {/*            <div className={styles['info-value']}>{indexes[INDEX_NAME]["docs.count"]}</div>*/}
                {/*        </div>*/}
                {/*        <div className={styles['info-row']}>*/}
                {/*            <div className={styles['info-label']}>Store size:</div>*/}
                {/*            <div className={styles['info-value']}>{indexes[INDEX_NAME]["store.size"]}</div>*/}
                {/*        </div>*/}
                {/*    </>}*/}
                {/*</div>*/}
                <div className={styles["search-box"]}>
                    <div className={styles["search-box-right"]}
                    >
                        <button type="button" className={styles["circle-button"]}
                                onClick={() => onSearch()}
                                title='use também CTRL+ENTER nas campos de input'
                        >
                            <FaArrowRight size={24}/>
                        </button>
                    </div>
                    <div className={styles["search-box-left"]}>
                        {/*<div className={styles["choice-bar"]}>*/}
                        {/*    <div*/}
                        {/*        className={styles['clickable-icon']}*/}
                        {/*        onClick = {(e)=> {*/}
                        {/*            const rect = e.target.getBoundingClientRect();*/}
                        {/*            setShowSaved({top:rect.top, left:rect.left});*/}
                        {/*        }}>*/}
                        {/*        /!* eslint-disable-next-line react/jsx-no-undef *!/*/}
                        {/*        <FaHistory size={20} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<h2>Busque por notícias...</h2>*/}
                        <div className={styles["keyword-bar"]}>
                            <div className={styles["filter-title"]}>
                                Busca por keyword
                            </div>
                            {/*<div className={styles["instructions"]}>Digite as chaves de busca: <span*/}
                            {/*    className={styles["instructions-bold"]}>eleição curitiba</span>. Use*/}
                            {/*    aspas duplas para palavra composta: <span className={styles["instructions-bold"]}>"são paulo"</span>*/}
                            {/*</div>*/}
                            <div className={styles["instructions"]}>Refine usando operadores (AND, OR, NOT): <span
                                className={styles["instructions-bold"]}>"corona vírus" AND vacina</span></div>
                            <input
                                type="text"
                                // placeholder="Enter your query..."
                                onChange={(e) => setKeywords(e.target.value)}
                                onKeyDown={handleInputKeyDown}
                                value={keywords}
                                // onKeyDown={handleSearch}
                            />
                            {/*<FaSearch className={styles["search-icon"]}/>*/}
                        </div>
                        <div className={styles["text-bar"]}>
                            <div className={styles["filter-title"]}>
                                Busca semântica
                            </div>
                            {/*<div className={styles["instructions"]}>Digite um texto para busca de uma notícia com a melhor*/}
                            {/*    semelhança semântica: Ex: <span*/}
                            {/*        className={styles["instructions-bold"]}>apuração do resultado da eleições municipais 2024 em são paulo</span>*/}
                            {/*</div>*/}
                            <textarea
                                // type="text"
                                placeholder="Enter your query..."
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyDown={handleInputKeyDown}
                                value={query}
                                // onKeyDown={handleSearch}
                            />
                            {/*<FaSearch className={styles["search-icon"]}/>*/}
                        </div>
                        {/*<div className={styles["choice-bar"]}>*/}
                        {/*    <div className={styles["instructions"]}>*/}
                        {/*        Tratar os critérios acima de busca como UNION ou AND.*/}
                        {/*        Selecionado: {isUnion ? 'UNION' : 'AND'}*/}
                        {/*    </div>*/}
                        {/*    <div className={styles["toggle-container"]}>*/}
                        {/*        <div className={styles["toggle-switch"]} onClick={() => setIsUnion(true)}>*/}
                        {/*            {isUnion ? (*/}
                        {/*                <FaCheckCircle size={24} color="#cda869"/>*/}
                        {/*            ) : (*/}
                        {/*                <FaCircle size={24}/>*/}
                        {/*            )}*/}
                        {/*        </div>*/}
                        {/*        <span className={styles["label"]}>*/}
                        {/*      UNION*/}
                        {/*    </span>*/}
                        {/*        <div className={styles["toggle-switch"]} onClick={() => setIsUnion(false)}>*/}
                        {/*            {!isUnion ? (*/}
                        {/*                <FaCheckCircle size={24} color="#cda869"/>*/}
                        {/*            ) : (*/}
                        {/*                <FaCircle size={24}/>*/}
                        {/*            )}*/}
                        {/*        </div>*/}
                        {/*        <span className={styles["label"]}>*/}
                        {/*      AND*/}
                        {/*    </span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*sortBy*/}
                        {/*<h2>Agregado por...</h2>*/}
                        {/*<div className={styles["choice-bar"]}>*/}
                        {/*    <select*/}
                        {/*        name="aggregate_by"*/}
                        {/*        value={aggregateBy}*/}
                        {/*        onChange={(e) => setAggregateBy(e.target.value)}*/}
                        {/*    >*/}
                        {/*        {aggOptions.map((opt) => (*/}
                        {/*            <option key={opt.code} value={opt.code}>*/}
                        {/*                {opt.name}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<h2>Ordenado por...</h2>*/}
                        {/*<div className={styles["choice-bar"]}>*/}
                        {/*    <select*/}
                        {/*        name="sortBy"*/}
                        {/*        value={sortBy}*/}
                        {/*        onChange={(e) => setSortBy(e.target.value)}*/}
                        {/*    >*/}
                        {/*        {sortOptions.map((opt) => (*/}
                        {/*            <option key={opt.code} value={opt.code}>*/}
                        {/*                {opt.name}*/}
                        {/*            </option>*/}
                        {/*        ))}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<h2>Filtrados por...</h2>*/}
                        {/*<div className={styles["instructions"]}>(filtro não interfere no score)</div>*/}
                        {/*{filterOptions}*/}

                        {/*<hr className={`separator ${styles["margin-top"]}`}/>*/}
                        {/*<h2>Radar</h2>*/}
                        {/*<div className={styles["instructions"]}>Salvar a busca e ativar monitoração</div>*/}
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    placeholder="Nome do radar.."*/}

                        {/*    onChange={(e) => setRadarName(e.target.value)}*/}
                        {/*    // onKeyDown={handleInputKeyDown}*/}
                        {/*    value={radarName}*/}
                        {/*    // onKeyDown={handleSearch}*/}
                        {/*/>*/}
                        {/*<div><button*/}
                        {/*    disabled={!!!radarName}*/}
                        {/*    onClick={()=>criarEAtivarRadar()} className={"button-alert"}>Criar e Ativar Radar</button></div>*/}

                    </div>
                </div>
            </div>
            {divSavedSearchModal}
        </>
    );
};

export default SearchPanel;
