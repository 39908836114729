import styles from "./PlainQuery.module.css"
import React, {useState} from "react";
import {setLoading, setSearchResults, useGlobalDispatch} from "../../context/GlobalState";
import {runPlainQuery, searchDocuments} from "../../services/api";
import {useAuth} from "../../hooks/useAuth";
import ReactJson from "react-json-view";

const PlainQuery = () => {
    const dispatch = useGlobalDispatch();
    const [query, setQuery] = useState('{\n' +
        '  "from": 0,\n' +
        '  "size": 1,\n' +
        '  "_source": {\n' +
        '    "excludes": [\n' +
        '      "embedding"\n' +
        '    ]\n' +
        '  },\n' +
        '  "sort": [\n' +
        '    {\n' +
        '      "indexed_at": {\n' +
        '        "order": "desc"\n' +
        '      }\n' +
        '    }\n' +
        '  ],\n' +
        '  "query": {\n' +
        '    "bool": {\n' +
        '      "must": [\n' +
        '        {\n' +
        '          "bool": {\n' +
        '            "should": []\n' +
        '          }\n' +
        '        }\n' +
        '      ],\n' +
        '      "filter": []\n' +
        '    }\n' +
        '  }\n' +
        '}\n')
    const [result, setResult] = useState(null);
    const { user, isAuthenticated, logout } = useAuth();

    const runQuery = async () => {
        try {
            dispatch(setLoading(true));
            setResult( await runPlainQuery(user.token, query));
        } catch (error) {
            console.error('Error:', error);
        } finally {
            dispatch(setLoading( false));
        }
    }

    const handleInputKeyDown = async (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Prevent the default action to avoid any unwanted behavior
            event.preventDefault();
            await runQuery();
        }
    }

    return (
        <div className={styles["container"]}>
            <div className={styles["query-container"]}>
                <div className={styles["query-panel-control"]}>
                    <h2>Plain query editor</h2>
                    <span>CTRL+ENTER to run the query</span>
                </div>
                <div className={styles["query-editor"]}>
                    <textarea
                        value={query}
                        onChange={e=> setQuery(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        placeholder={"Edit you query and enter CTRL+ENTER"}
                        />
                </div>

            </div>
            <div className={styles["result-container"]}>
                {!!result && <div><ReactJson src={result} theme={"twilight"}/></div>}
            </div>
        </div>
    )
}

export default PlainQuery;
