import { useGlobalState, useGlobalDispatch, setUser, logoutUser } from '../context/GlobalState';
import { login as loginService } from '../services/api';

export const useAuth = () => {
    const { user } = useGlobalState();
    const dispatch = useGlobalDispatch();

    const login = async (username, password) => {
        const token = await loginService(username, password);
        const userData = { username, token };
        localStorage.setItem('user', JSON.stringify(userData));
        dispatch(setUser(userData));
    };

    const logout = () => {
        localStorage.removeItem('user');
        dispatch(logoutUser());
    };

    const isAuthenticated = () => {
        if (!user || !user.token) return false;

        const tokenExpiration = JSON.parse(atob(user.token.split('.')[1])).exp * 1000;
        if (Date.now() >= tokenExpiration) {
            logout();
            return false;
        }

        return true;
    };

    return { user, login, logout, isAuthenticated };
};
