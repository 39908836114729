import React, {useEffect, useMemo, useState} from 'react';
import styles from './SearchViewer.module.css';
import {
    setSearchResults,
    useGlobalDispatch,
    useGlobalState,
    setLoading,
    setInfoMessage
} from "../../context/GlobalState";
import {useAuth} from "../../hooks/useAuth";
import { AiOutlineClose } from 'react-icons/ai';
import ReactJson from 'react-json-view';
import {FaArrowLeft, FaArrowRight, FaCheckCircle, FaCircle, FaToggleOff, FaToggleOn} from "react-icons/fa";
import {createAndActivateRadar, saveSearch, searchDocuments} from "../../services/api";
import AggregationChart from "./AggregationChart";
import DocumentCard from "../DocumentCard/DocumentCard";
import {SectionTitle, SimpleSubTitle, SubSectionTitle, SubTitle} from "../Headings/Heading";


const SUMMARY_LIMIT = 140;

const aggOptions = [
    {code: "document_date", name: "data do documento"},
    {code: "source", name: "fonte"},
    {code: "location", name: "localidade"},
    {code: "subjects", name: "assunto"},
    {code: "entities", name: "ente"},
    {code: "authors", name: "autor"},
    {code: "main_topics", name: "tema principal"},
    {code: "sentiment", name: "sentimento"},
]

const SearchViewer = () => {
    const dispatch = useGlobalDispatch();
    const {searchResults} = useGlobalState();
    const {user, isAuthenticated, logout} = useAuth();
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState(0);
    const [detail, setDetail] = useState(null);
    // const [osQuery, setOsQuery] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [aggregate, setAggregate] = useState([]);
    const [showHisto, setShowHisto] = useState(true);
    const [alertOn, setAlertOn] = useState(false);
    const [searchSaved, setSearchSaved] = useState(false);
    const [queryTotal, setQueryTotal] = useState(null);

    useEffect(() => {
        if (searchResults) {
            let total = searchResults.results.hits?.total?.value || 0;
            if (total> 0) {
                // console.log('searchResults: ', JSON.stringify(searchResults));
                setResults(searchResults.results?.hits?.hits || []);
                setTotal(searchResults.results.hits?.total?.value);
                setAggregate(searchResults.results?.aggregations)
                setDetail(searchResults.results?.query);
                setAlertOn(false);
                setSearchSaved(false);
                setQueryTotal(searchResults.results.hits?.total);
            } else {
                setDetail(searchResults.results?.query);
                setQueryTotal(searchResults.results.hits?.total);
                dispatch(setInfoMessage("Nenhum resultado encontrado"));
            }
        }
    }, [searchResults]);



    const onSearch = async (from=0) => {
        try {
            dispatch(setLoading(true));
            const {  query, keywords, isUnion, size, filters, sortBy, url, title, isExactKeyword, aggregateBy } = searchResults;
            const results = await searchDocuments(user.token, query, keywords, isUnion? "union": "and",
                isExactKeyword, url, title,
                filters,
                sortBy, aggregateBy, from, size);
            dispatch(setSearchResults({ ...searchResults, from, results}));
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading( false));
        }
    };
    const handleNextPage = async () => {
        const newFrom = searchResults.from + searchResults.size;
        await onSearch(newFrom)
    }

    const handlePrevPage = async () => {
        let newFrom = searchResults.from - searchResults.size;
        if (newFrom < 0) newFrom = 0;
        await onSearch(newFrom)
    }

    const switchAggregate = async (aggregateBy) => {
        console.log(searchResults?.aggregateBy, '=> ', aggregateBy);
        if (searchResults?.aggregateBy == aggregateBy)
            return;
        try {
            dispatch(setLoading(true));
            const {  query, keywords, isUnion, size, filters, sortBy, url, title, from, isExactKeyword } = searchResults;
            const results = await searchDocuments(user.token, query, keywords, isUnion? "union": "and",
                isExactKeyword, url, title,
                filters,
                sortBy, aggregateBy, from, size);
            dispatch(setSearchResults({ ...searchResults, aggregateBy, results}));
        } catch (error) {
            console.error('Error!', error);
        } finally {
            dispatch(setLoading( false));
        }
    }

    const trimQuery = (query) => {
        const maxLength = 100;
        if (query && query.length > maxLength) {
            return query.substring(0, maxLength) + '...';
        }
        return query;
    };

    const renderMessage = () => {
        let message = `Encontrados ${total} artigos`;

        const hylightStyle = styles["highlight"]
        if (searchResults.keywords) {
            message += ` relacionados a <span class="${hylightStyle}">${searchResults.keywords}</span>`;
        }

        if (searchResults.query) {
            if (searchResults.keywords) {
                message += ` e`;
            } else {
                message += ` relacionados a `;
            }
            message += ` <span class="${hylightStyle}">${trimQuery(searchResults.query)}</span>`;
        }

        return message;
    };

    const handleToggleAlert = async () => {
        const {query, keywords, isUnion, size, filters, sortBy, aggregateBy, isExactKeyword, url} = searchResults;
        // const _filters = {...filters}
        // _filters['sentiment'] = Object.entries(_filters['sentiment'])
        //     .filter(([key, value]) => value === true)
        //     .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            const _alert = await createAndActivateRadar(user.token, null, query, keywords,
                isUnion ? "union" : "and",
                isExactKeyword,
                url,
                filters);
            setAlertOn(true);
            alert(`Criado alerta para ${_alert?.name}. Confira na aba de alertas` )
        } catch (error) {
            alert(error.message);
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const handleSaveSearch = async () => {
        const {query, keywords, isUnion, size, filters, sortBy, aggregateBy, isExactKeyword, url} = searchResults;
        const _filters = {...filters}
        _filters['sentiment'] = Object.entries(_filters['sentiment'])
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        try {
            dispatch(setLoading(true));
            const _alert = await saveSearch(user.token, query, keywords, isUnion,
                isExactKeyword,
                url,
                _filters, sortBy, aggregateBy);
            setSearchSaved(true);
            alert(`Criado alerta para ${_alert?.name}. Confira na aba de alertas` )
        } catch (error) {
            alert(error.message);
            console.error('Error fetching search results:', error);
        } finally {
            dispatch(setLoading(false));
        }
    }

    const searchParametersDiv = useMemo(()=> {
        if (searchResults) {
            let filters = null;
            if (searchResults?.filters) {
                filters = Object.entries(searchResults?.filters).reduce((acc, [key, value]) => {
                    // Exclude the 'results' field and filter out nullable values
                    if (
                        value !== null && // Exclude null
                        value !== undefined && // Exclude undefined
                        value !== "" && // Exclude empty strings
                        !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
                    ) {
                        acc[key] = value; // Add the key-value pair to the target object
                    }
                    return acc;
                }, {});
            }

            const target = Object.entries(searchResults).reduce((acc, [key, value]) => {
                // Exclude the 'results' field and filter out nullable values
                if (
                    key !== "results" && // Exclude 'results'
                    key !== "filters" && // Exclude 'results'
                    value !== null && // Exclude null
                    value !== undefined && // Exclude undefined
                    value !== "" && // Exclude empty strings
                    !(Array.isArray(value) && value.length === 0) // Exclude empty arrays
                ) {
                    acc[key] = value; // Add the key-value pair to the target object
                }
                if (filters) {
                    acc["filters"] = filters;
                }
                return acc;
            }, {});

            return <ReactJson src={target} theme={"twilight"}/>
        } else {
            return null;
        }
    }, [searchResults])

    const detailContent = detail && (
        <div className={styles["main-content-right"]}>
            <button
                onClick={() => setDetail(null)}
                className={styles['close-button']}
                // style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
                <AiOutlineClose size={24}/>
            </button>
            <SectionTitle>Agregado por...</SectionTitle>
            <div className={"select"}>
                <select
                    name="aggregate_by"
                    onChange={(e) => switchAggregate(e.target.value)}
                    value={searchResults?.aggregateBy || ""}
                >
                    {aggOptions.map((opt) => (
                        <option key={opt.code} value={opt.code}>
                            {opt.name}
                        </option>
                    ))}
                </select>
            </div>
            <AggregationChart new_aggregate={aggregate}/>
            <div className={"top-border"}>
                <SimpleSubTitle>Search parameters</SimpleSubTitle>
            </div>
            {searchParametersDiv}
            <div className={styles["toggle-container"]}>
                <div className={styles["toggle-container-option"]}>
                    <span>
                                  set Alert
                                </span>
                    <div className={styles["toggle-switch"]} onClick={() => handleToggleAlert(!alertOn)}>
                        {alertOn ? (
                            <FaToggleOn size={30} color="#cda869"/>
                        ) : (
                            <FaToggleOff size={30}/>
                        )}
                    </div>
                </div>
                <div className={styles["toggle-container-option"]}>
                    <span>
                                  salvar busca
                                </span>
                    <div className={styles["toggle-switch"]} onClick={() => handleSaveSearch()}>
                        {searchSaved ? (
                            <FaToggleOn size={30} color="#cda869"/>
                        ) : (
                            <FaToggleOff size={30}/>
                        )}
                    </div>
                </div>
            </div>
            <div className={"top-border"}>
                <SimpleSubTitle>Total</SimpleSubTitle>
            </div>
            <div className={"top-border"}>
                <ReactJson src={queryTotal} theme={"twilight"}/>
            </div>
            <div className={"top-border"}>
                <SimpleSubTitle>Search result or Document detail</SimpleSubTitle>
            </div>
            <div className={styles['detail']}>
                <ReactJson src={detail} theme={"twilight"}/>
                {/*<JsonView data={detail} shouldExpandNode={allExpanded}/>*/}
                {/*<div>{JSON.stringify(detail)}</div>*/}
            </div>
        </div>
    );

    if (!results || results.length <= 0) {
        return (
            <div className={styles["main-content"]}></div>
        );
    }

    const mainList = <div className={styles["main-content-left"]}>
        <div className={styles["result-bar"]}>
            {/*{loading && <div className={"loading"}>loading..</div>}*/}

            <div className={styles["encontrados"]} dangerouslySetInnerHTML={{__html: renderMessage()}}/>
            <div>Exibidos {searchResults.from} a {searchResults.from + results.length} artigos</div>
            <div className={styles["pagination-controls"]}>
                <button
                    className={styles["prev-button"]}
                    onClick={handlePrevPage} // Placeholder for previous page handler
                    disabled={searchResults.from === 0} // Disable if on the first page
                >
                    <FaArrowLeft/>
                </button>

                <button
                    className={styles["next-button"]}
                    onClick={handleNextPage} // Placeholder for next page handler
                    disabled={searchResults.from + searchResults.size >= total} // Disable if on the last page
                >
                    <FaArrowRight/>
                </button>
            </div>
        </div>
        <div className={styles["result-list"]}>
            {results.map((result, index) => (
                <DocumentCard
                    key={index}
                    result={result}
                    onClick={setDetail}
                    filters={searchResults.filters}
                    />
            ))}
        </div>
    </div>


    return ( <>
            <div className={styles["main-container"]}>
                {mainList}
                {detailContent}
            </div>
        </>
    );
};

export default SearchViewer;
