import styles from './SavedSearch.module.css'
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {
    deleteAlert, deleteSavedSearch,
    getAlertDetail,
    getAlerts,
    getAlertUnseenDocuments,
    getSavedSearchList,
    resetAlert, toggleSaveSearchAlert
} from "../../services/api";
import {FaRegTrashCan} from "react-icons/fa6";
import DocumentCard from "../DocumentCard/DocumentCard";
import {useGlobalState, setLoading, useGlobalDispatch} from "../../context/GlobalState";
import {AiOutlineClose} from "react-icons/ai";
import AggregationChart from "../SearchViewer/AggregationChart";
import ReactJson from "react-json-view";
import {LuTimerReset} from "react-icons/lu";
import {FaToggleOff, FaToggleOn} from "react-icons/fa";

const SavedSearch = () => {
    const dispatch = useGlobalDispatch();
    const [savedSearchList, setSaveSearchList] = useState([])
    const { user, isAuthenticated, logout } = useAuth();
    const [detail, setDetail] = useState(null);
    const [result, setResult] = useState({});

    useEffect(()=> {
        fetchData();
    }, [])

    const fetchData = async () => {
        const result = await getSavedSearchList(user.token)
        setSaveSearchList( result)
    }

    const handleDeleteSavedSearch = async (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("Delete saved search?")) return;
        await deleteSavedSearch(user.token, id);
        fetchData();
    }

    const handleSelectAlert = async (id) => {
        const saved = savedSearchList.find(item => item.id === id);
        setDetail(saved);
    }

    const toggleAlertFor = async(_alert) => {
        try {
            dispatch(setLoading(true));
            await toggleSaveSearchAlert(user.token, _alert.id);
            fetchData();
        } finally {
            dispatch(setLoading(false));
        }
    }

    const detailContent = detail && (
        <div className={styles["alert-detail-content"]}>
            <ReactJson src={detail} theme={"twilight"}/>
        </div>
    );

    return (
        <div className={styles["container"]}>
            <div className={styles["alert-container"]}>
                <h2>Buscas salvas</h2>
                <div className={styles["alert-list-container"]}>
                    {savedSearchList.map((_alert, index) => (<div className={styles["row"]}>
                        <div className={styles["alert-title"]}
                             onClick={() => handleSelectAlert(_alert.id)}
                        >{_alert.name}</div>
                        <div className={styles["action-button"]}
                             onClick={() => handleDeleteSavedSearch(_alert.id)}
                             title={"delete alert"}
                        ><FaRegTrashCan/></div>
                        <div className={styles["toogle-alert"]}
                             onClick={() => toggleAlertFor(_alert)}
                        >
                            <span>Toogle alert</span>
                            {_alert?.alert_on ? (
                                <FaToggleOn size={30} color="#cda869"/>
                            ) : (
                                <FaToggleOff size={30}/>
                            )}
                        </div>
                    </div>))}
                </div>
            </div>
            {detailContent}
        </div>

    )
}

export default SavedSearch;
