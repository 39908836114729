import styles from "./NavBar.module.css";
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import {LuRadar} from "react-icons/lu";
import {MdMonitor, MdOutlineDraw, MdQueryStats} from "react-icons/md";
import {FaHistory, FaSearch} from "react-icons/fa";
import {IoChatbubbleEllipses} from "react-icons/io5";


const NavBar = () => {
    return (
        <div className={styles["nav-container"]}>
            <div className={styles["command-icons"]}>
                <div className={styles["logo"]}>
                    <img
                        src="/android-chrome-512x512.png"
                        alt="App Logo"
                        className={styles["app-logo"]}
                    />
                </div>
                <Link to="/plain-query" className={"fa-icon"} title={"opensearch plain query"}> {/* Navigate to Search */}
                    <MdOutlineDraw />
                </Link>
                <Link to="/search" className={"fa-icon"} title={"search"}> {/* Navigate to Search */}
                    <FaSearch/>
                </Link>
                <Link to="/saved-search" className={"fa-icon"} title={"buscas salvas"}> {/* Navigate to Radar */}
                    <FaHistory/>
                </Link>
                <Link to="/alert" className={"fa-icon"} title={"alertas"}> {/* Navigate to Radar */}
                    <LuRadar/>
                </Link>
                <Link to="/chat" className={"fa-icon"} title={"chat"}> {/* Navigate to Radar */}
                    <IoChatbubbleEllipses/>
                </Link>
                <Link to="/monitor" className={"fa-icon"} title={"system monitor"}> {/* Navigate to Search */}
                    <MdMonitor />
                </Link>
            </div>
        </div>

    )
}

export default NavBar;
