import React from 'react';
import styles from './Heading.module.css'

// Title Component
const Title = ({ children }) => {
    return <div className={styles["title"]}>{children}</div>;
};

// Subtitle Component
const SubTitle = ({ children }) => {
    return <div className={styles["sub-title"]}>{children}</div>;
};

// SectionTitle Component
const SectionTitle = ({ children }) => {
    return <div className={styles["section-title"]}>{children}</div>;
};

const SubSectionTitle = ({ children }) => {
    return <div className={styles["sub-section-title"]}>{children}</div>;
};

const SimpleSubTitle = ({ children }) => {
    return <div className={styles["simple-sub-title"]}>{children}</div>;
};



export { Title, SubTitle, SectionTitle, SubSectionTitle, SimpleSubTitle };